import { useEffect, MouseEvent } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';

import {
  getUser,
} from '../../store/user-process/user-process';
import {
  getVoters,
} from '../../store/voting-process/voting-process';
import {
  deleteVoter,
  fetchVotersByDirection,
} from '../../store/api-action';

import { Table, Tr, Td}  from '../../components/common/table/table';
import { UserType } from '../../const';
import socket from '../socket/socket';
import { Direction } from '../../utils/utils';

export default function MainTable(): JSX.Element {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const voters = useAppSelector(getVoters);
  const sortDirection = Direction.get();

  useEffect(() => {
    socket.on('eventVoterClient', (data) => {
      if (user?.type === data) {
        dispatch(fetchVotersByDirection(sortDirection));
      }
    });
  }, [dispatch, sortDirection, voters, user]);

  const handleClickDeleteVoter = (evt: MouseEvent<HTMLSpanElement>) => {
    const id = evt.currentTarget.dataset.id;
    id && dispatch(deleteVoter(id));
  }

  return (
    <Table id="date-table" className={user?.type === UserType.Regular ? `is-${UserType.Regular}` : ""}>
      {voters && voters.map(({id, family, name, surname, phone, registration, address, job, direction, isCurrent}) =>
        <Tr $isCurrent={isCurrent} key={registration} className={isCurrent ? "111" : ""} $primary={true}>
          <Td>{family}</Td>
          <Td>{name}</Td>
          <Td>{surname}</Td>
          <Td>{phone}</Td>
          <Td>{registration}</Td>
          <Td>{address}</Td>
          <Td>{job}</Td>
          <Td >{direction}</Td>
          {user?.type === UserType.Admin ? <Td data-id={id} onClick={handleClickDeleteVoter.bind(null)}><span></span></Td> : null}
        </Tr>
      )}
    </Table>
  );
}
