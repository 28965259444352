import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { TVotingProcess, TDirection, State } from '../../types/state';
import { DIRECTIONS, SubmitStatus } from '../../const';
import { TVoter, TIsActiveForm } from '../../types';
import {
  fetchVoters,
  fetchVotersByDirection,
  postVoter,
  deleteVoter,
  fetchStatusForm,
  changeStatusForm,
  changeCurrentStatusVoters
} from '../api-action';
// import { Token } from '../../utils/utils';

const initialState: TVotingProcess = {
  // date: null,
  currentDirection: DIRECTIONS[0],
  sortDirection: 'all',
  isVotersLoading: false,
  voters: [],
  isVoterSendingStatus: SubmitStatus.Still,
  activeForm: []
};


export const votingProcess = createSlice({
  name: 'VOTING',
  initialState,
  reducers: {
    setSortDirection: (state, action: PayloadAction<TDirection>) => {
      state.sortDirection = action.payload;
      state.voters = state.voters.filter((voter) => voter.direction === action.payload);
    },
    resetVoterSendingStatus: (state) => {
      state.isVoterSendingStatus = SubmitStatus.Still;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVoters.pending, (state) => {
        state.voters = [];
        state.isVotersLoading = true;
      })
      .addCase(fetchVoters.rejected, (state) => {
        state.voters = [];
        state.isVotersLoading = false;
      })
      .addCase(fetchVoters.fulfilled, (state, action) => {
        state.voters = action.payload;
        state.isVotersLoading = false;
      })
      .addCase(fetchVotersByDirection.pending, (state) => {
        state.voters = [];
        state.isVotersLoading = true;
      })
      .addCase(fetchVotersByDirection.rejected, (state) => {
        state.voters = [];
        state.isVotersLoading = false;
      })
      .addCase(fetchVotersByDirection.fulfilled, (state, action) => {
        state.voters = action.payload;
        state.isVotersLoading = false;
      })
      .addCase(postVoter.pending, (state) => {
        state.isVoterSendingStatus = SubmitStatus.Pending;
      })
      .addCase(postVoter.rejected, (state) => {
        state.isVoterSendingStatus = SubmitStatus.Rejected;
      })
      .addCase(postVoter.fulfilled, (state) => {
        state.isVoterSendingStatus = SubmitStatus.Fullfilled;
      })
      .addCase(deleteVoter.pending, (state) => {
        state.isVotersLoading = true;
      })
      .addCase(deleteVoter.fulfilled, (state) => {
        state.isVotersLoading = false;
        // const deleteVoterId = action.payload;
        // state.voters = state.voters.filter((voter) => voter.id !== deleteVoterId.toString());
      })
      .addCase(changeCurrentStatusVoters.pending, (state) => {
        state.isVotersLoading = true;
      })
      .addCase(changeCurrentStatusVoters.rejected, (state) => {
        state.isVotersLoading = true;
      })
      .addCase(changeCurrentStatusVoters.fulfilled, (state) => {
        state.isVotersLoading = false;
      })



      .addCase(fetchStatusForm.pending, (state) => {
        state.isVotersLoading = true;
      })
      .addCase(fetchStatusForm.fulfilled, (state, action) => {
        state.activeForm = action.payload;
        state.isVotersLoading = false;
      })

      .addCase(changeStatusForm.pending, (state) => {
        state.isVotersLoading = true;
      })
      .addCase(changeStatusForm.fulfilled, (state, action) => {
        state.activeForm = action.payload;
        state.isVotersLoading = false;
      })

  }
});

export const { setSortDirection, resetVoterSendingStatus } = votingProcess.actions;

export const getIsVotersLoading = (state: State): boolean => state['VOTING'].isVotersLoading;
export const getVoters = (state: State): TVoter[] => state['VOTING'].voters;
export const getSortDirection = (state: State): string => state['VOTING'].sortDirection;
export const getcurrentDirection = (state: State): string => state['VOTING'].currentDirection;
export const getIsVoterSendingStatus = (state: State): SubmitStatus => state['VOTING'].isVoterSendingStatus;
export const getActiveForm = (state: State): TIsActiveForm[] => state['VOTING'].activeForm;

export const selectVoters = createSelector(
  [getVoters, getSortDirection],
  (voters, sortDirection,) => voters.filter((voter) => {
    if (sortDirection === "all") {
      return voters;
    } else {
      return voter.direction === sortDirection;
    }
  })
);

export const currentVotersTheDay = createSelector(
  [getVoters, getSortDirection],
  (voters, sortDirection) => voters.filter((voter) => voter.isCurrent && voter.direction === sortDirection).length
);

export const currentVotersTheDayForAdmin = createSelector(
  [getVoters],
  (voters) => voters.filter((voter) => voter.isCurrent).length
);

export const getAllVoters = createSelector(
  [getVoters],
  (voters) => voters.map((voter) => voter).length
);

export const getIsActiveForm = createSelector(
  [getActiveForm],
  (form) => form.map((item) => item.isActive)[0]
);

export const getIdActiveForm = createSelector(
  [getActiveForm],
  (form) => form.map((item) => item._id)[0]
);

// export const getIdActiveForm = createSelector(
//   [getActiveForm],
//   (form) => console.log(form)
// );



// export const selectComments = createSelector(
//   [getComments],
//   (comments) => [...comments].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).slice(0, MAX_COMMENTS)
// );
