import 'react-toastify/dist/ReactToastify.css';
import { TSubmitUser } from '../../types';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import TextField from '@mui/material/TextField';

import { loginUser } from '../../store/api-action';
import './form-main.css';
import { useForm } from 'react-hook-form';

export default function FormMain(): JSX.Element {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<TSubmitUser>({defaultValues: {email: '', password: ''}, mode: 'onChange'})


  // const handleSubmitForm = (evt: React.FormEvent<HTMLFormElement>) => {
  //   evt.preventDefault();
  //   const form = evt.currentTarget;
  //   const formData = new FormData(form) as Iterable<[TSubmitUser]>;
  //   const data = Object.fromEntries(formData);
  //   dispatch(loginUser(data));
  //   setTimeout(() => {
  //     form.reset();
  //   }, 300);
  // };

  const handleSubmitForm = (data: TSubmitUser) => {
    dispatch(loginUser(data));
  };


  return (
    <form action="#" method="post" onSubmit={handleSubmit(handleSubmitForm)}>
      <TextField
        required
        autoFocus
        label = 'Логин'
        fullWidth
        id='email'
        // name='email'
        autoComplete='off'
        type='email'
        {...register("email", {required: 'Укажите логин-почту'})}
        helperText={errors.email?.message}
        error={Boolean(errors.email?.message)}
      />
      <TextField
        required
        label = 'Пароль'
        fullWidth
        id='password'
        // name='password'
        autoComplete='off'
        type='password'
        {...register("password", {required: 'Укажите пароль'})}
        helperText={errors.password?.message}
        error={Boolean(errors.password?.message)}
      />
      {/* <div className="custom-input entrance__input" data-freeze-label="parent">
        <label htmlFor="email">
          <input type="text" id="email" name="email" autoComplete="off" placeholder="логин" data-freeze-label="field"/>
        </label>
      </div>
      <div className="custom-input entrance__input" data-freeze-label="parent">
        <label htmlFor="password">
          <input type="password" id="password" autoComplete="off" name="password" placeholder="пароль" data-freeze-label="field"/>
        </label>
      </div> */}
      <button disabled={!isValid} className="btn entrance__button" type="submit"><span>войти</span>
      </button>
    </form>
  );
}
