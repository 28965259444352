import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';
import FormMain from '../../components/form-main/form-main';
import {useAppSelector} from '../../hooks/useAppSelector';
import {getAuthorizationStatus} from '../../store/user-process/user-process';
import { AuthorizationStatus } from '../../const';
import {toast} from 'react-toastify';
import { useEffect } from 'react';
import './entrance.css';
import socket from '../../components/socket/socket';


export default function Entrance(): JSX.Element {

  const statusAuthorization = useAppSelector(getAuthorizationStatus);

  useEffect(() => {
    if (statusAuthorization === AuthorizationStatus.Auth ) {
      toast.info('Добро пожаловать');
      socket.on('connect', () => {
        console.log(`${socket.id} connected is - ${socket.connected}`);
      });
    } else {
      socket.disconnect();
      toast.info('До новых встреч');
    }
  },[statusAuthorization]);

  return (
    statusAuthorization !== AuthorizationStatus.Auth ?
      <main className='main-entrance'>
        <Helmet>
          <title>VOTE</title>
        </Helmet>

        <section className="entrance">
          <h2 className="visually-hidden">вход в систему</h2>
          <div className="container">
            <div className="entrance__wrapper">
              <p className="entrance__title">введите логин и&nbsp;пароль для&nbsp;входа&nbsp;в&nbsp;систему</p>
              <FormMain />
            </div>
          </div>
        </section>
      </main> : <Navigate to='/main' />
  );
}
