import styled, { css } from 'styled-components';

export const Table = styled.table`
  font-size: 12px;
  line-height: 14px;
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
  color: ${({ theme }) => theme.color.black};

  /* @media (max-width: 767px) {
    margin: 0 5px;
  } */

  & tbody {
    border-spacing: 0px;
    width: 100%;
    border-collapse: collapse;
  }
`;

export const TBody = styled.tbody`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
`;

export const Tr = styled.tr.attrs({ tabIndex: 0 })`
  width: 100%;
  display: grid;
  grid-template-columns: 100px 90px 110px 105px 90px 2fr 1fr 100px 8px;


  .is-regular & {
    grid-template-columns: 100px 90px 110px 105px 90px 2fr 1fr 100px;
  }

  position: relative;
  background-color: ${({ theme }) => theme.color.white};

  &::before {
    display: flex;
    position: absolute;
    left: -1px;
    top: -1px;
    content: "";
    width: 5px;
    height: 5px;
    background-color: rgba(255, 255, 255, 0);
  }

  &::after {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.nepal};
  }

  ${({ $isCurrent }) =>
    $isCurrent && css`
      &::before {
        background-color: ${({ theme }) => theme.color.lightSeaGreen};
      }
    `
  }

  &:hover {
    & td {
      background-color: ${({ theme }) => theme.color.grayLight};
    }
  }

  &:focus {
    outline: none;

    &::after {
      height: 2px;
      bottom: -2px;
    }

    & td {
      background-color: ${({ theme }) => theme.color.grayFormText};
    }
  }

  &:first-child {
    & td {
      border-top: none;
    }
  }


  &:last-child {
    &::after {
      display: none;
    }
  }
`;

export const Td = styled.td`
  position: relative;
  z-index: 2;
  border-spacing: 0px;
  height: 100%;
  outline: none;
  /* margin-top: -1px; */
  /* border-top: 1px solid ${({ theme }) => theme.color.nepal}; */
  border-right: 1px solid ${({ theme }) => theme.color.nepal};

  &:last-child {
    border-right: none;
  }

  padding: 6px 10px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(9) {
    position: relative;
    padding: 0;

    & span {
      position: absolute;
      left: 0;
      top: 0;
      outline: none;
      border: none;
      margin: 0;
      padding: 0;
      display: block;
      background-color: rgba(255, 255, 255, 0);
      min-width: 15px;
      min-height: 50px;
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.color.red};
      }
    }
  }
`;
