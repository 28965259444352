import styled from 'styled-components';
import { ReactComponent as iconHeader } from '../../../assets/icons/icon-header.svg';
// import { Container, Link as RouterLink } from 'components/common/common';
import { ReactComponent as outHeader } from '../../../assets/icons/out.svg';

const StyledHeaderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.color.white};
  background: transparent;
  margin-right: 15px;

  border: none;
  cursor: pointer;
  white-space: nowrap;

  &:hover:not(:disabled) {
    & svg {
      fill: ${({ theme }) => theme.color.lightSeaGreen};
    }
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.color.chambrayLight};
    outline-offset: 2px;
  }

  &:active:not(:disabled) {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.2;
    cursor: wait;
  }
`;

const StyledIconOut = styled(outHeader)`
  display: flex;
  width: 30px;
  height: 25px;
  color: ${({ theme }) => theme.color.nepal};
`

const StyledHeader = styled.header`
  width: 100vw;
  background-color: ${({ theme }) => theme.color.chambray};

  @media (max-width: 850px) {
    width: 100%;
  }
`;

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 40px;
  padding-left: 40px;

  @media (max-width: 850px) {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 22px;
  }
`;

const HeaderTitle = styled.p`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  flex-shrink: 0;
  margin: 0;
  font-size: 18px;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.white};

  @media (max-width: 850px) {
    left: 0;
    transform: translateX(0);
    text-align: center;
    margin-bottom: 10px;
  }
`;

const HeaderIconWrapper = styled.div`
  flex-shrink: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;

  @media (max-width: 850px) {
    margin-left: 0;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  font-size: 16px;

  @media (max-width: 850px) {
    font-size: 14px;
  }
`;

const StyledIconHeader = styled(iconHeader)`
  display: flex;
  width: 60px;
  height: 60px;

  @media (max-width: 850px) {
    width: 40px;
    height: 40px;
  }

  /* path {
    fill: ${({ theme }) => theme.color.red};
  } */

  /* &:focus circle,
  &:hover circle {
    fill: #4f779f;
  } */

  /* &:focus path,
  &:hover path {
    fill: white;
  } */
`

const HeaderSpan = styled.span`
  display: block;
  margin-bottom: 5px;

  @media (max-width: 850px) {
    margin-bottom: 0;
  }
`;

const HeaderUserName = styled.p.attrs({ className: 'user-name' })`
  margin: 0;
  font-weight: 700;
`;
/* const Image = styled.img``; */

const Links = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  /* max-width: 600px; */
  margin: 0;
  padding: 0;
  padding-top: 17px;
  padding-bottom: 17px;
  margin-bottom: -10px;
  list-style: none;
`;

const LinkItem = styled.li`
  &:not(:last-child) {
    margin-right: 49px;
    margin-bottom: 10px;
  }
`;

// const Link = styled(RouterLink)`
//   display: block;
//   max-width: 100px;
//   font-size: ${({ theme }) => theme.font.semibase};
//   line-height: 16px;
//   letter-spacing: 0.03em;
//   font-weight: 600;
//   text-transform: uppercase;

//   color: ${({ theme }) => theme.color.whiteSmoke};

//   ${({ $isActiveLink }) =>
//     $isActiveLink &&
//     css`
//       color: ${({ theme }) => theme.color.tangerine};
//     `}

//   &:focus,
//   &:hover {
//     color: ${({ theme }) => theme.color.tangerine};
//   }
// `;

// const Phone = styled.a`
//   margin-top: 17px;
//   margin-left: auto;
//   font-size: ${({ theme }) => theme.font.semibase};
//   line-height: 16px;
//   letter-spacing: 0.03em;
//   font-weight: 600;
//   color: ${({ theme }) => theme.color.whiteSmoke};

//   font-feature-settings: 'pnum' on, 'lnum' on;

//   &:focus,
//   &:hover {
//     color: ${({ theme }) => theme.color.tangerine};
//   }
// `;

export {
  StyledHeader,
  HeaderWrapper,
  StyledIconHeader,
  HeaderTitle,
  HeaderUserName,
  HeaderIconWrapper,
  Links,
  LinkItem,
  HeaderTextWrapper,
  HeaderSpan,
  StyledHeaderButton,
  StyledIconOut,
};
