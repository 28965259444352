import { ThemeProvider } from 'styled-components';
import { appTheme } from './common';
import * as S from './app.styled';

import { Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Main from '../../pages/main/main';
import Entrance from '../../pages/entrance/entrance';
import NotFound from '../../pages/not-found/not-found';
import PrivateRoute from '../private-route/private-route';
import { AuthorizationStatus } from '../../const';

import HistoryRouter from '../history-router/history-router';
import browserHistory from '../../browser-history';

function App(): JSX.Element {

  return (
    <ThemeProvider theme={appTheme}>
      <S.GlobalStyle />
      <HelmetProvider>
        <HistoryRouter history={browserHistory}>
          <Routes>
            <Route index element={<Entrance />} />

            {/* <Route path={'/main'} element={<Main />} /> */}
            <Route
              path={'/main'}
              element={
                <PrivateRoute restrictedFor={AuthorizationStatus.NoAuth} redirectTo={'/'}>
                  <Main />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFound />} />

          </Routes>
        </HistoryRouter>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
