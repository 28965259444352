
import * as S from './header.styled';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getUser } from '../../../store/user-process/user-process';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { logoutUser } from '../../../store/api-action';


const Header = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);

  const handleOutUser = () => {
    dispatch(logoutUser());
  }

  return (
    <S.StyledHeader>
      <S.HeaderWrapper>
        <S.HeaderTitle>Форма регистрации {user.type === 'regular' && `. ${user.direction}`}</S.HeaderTitle>
        <S.HeaderIconWrapper>

          <S.StyledHeaderButton onClick={handleOutUser}><S.StyledIconOut/></S.StyledHeaderButton>

          <S.StyledIconHeader/>
          <S.HeaderTextWrapper>
            <S.HeaderSpan>пользователь</S.HeaderSpan>
            <S.HeaderUserName>{user?.email}</S.HeaderUserName>
          </S.HeaderTextWrapper>
        </S.HeaderIconWrapper>
        {/* <S.Phone href="tel:88003335599">8 (800) 333-55-99</S.Phone> */}
      </S.HeaderWrapper>
    </S.StyledHeader>)
};

export default Header;
