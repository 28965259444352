import styled from 'styled-components';

const StyledSelectDiv = styled.div.attrs({className: 'custom-select'})`
  position: relative;
  flex: 1 1 10%;
  font-size: 1em;
  color: black;
  background-color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: 767px) {
    padding: 4px 0 14px;
    width: 100%;
  }

  &.is-disabled {
    pointer-events: none;
    cursor: text;
  }

  & .custom-select__label {
    position: absolute;
    top: 50%;
    left: 0;
    padding: 19px;
    transform: translateY(-50%);
    transition: transform 0.3s ease, opacity  0.3s ease;
    pointer-events: none;

    @media screen and (max-width: 767px) {
      transform: translateY(calc(-50% - 5px));
    }
  }

  & .custom-select__placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 19px;
    color: ${({ theme }) => theme.color.nepal};
    text-transform: uppercase;
    transition: transform 0.3s ease, opacity 0.3s ease;
    pointer-events: none;
    font-size: 14px;

    @media screen and (max-width: 767px) {
      transform: translateY(calc(-50% - 5px));
    }
  }

  & button {
    min-height: 56px;
    min-width: 200px;
  }

  select {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  & .custom-select__text {
    flex-shrink: 0;
    width: 80%;
    overflow: hidden;

    /* padding: 19px; */
    font-size: 1em;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    opacity: 0;

    transition: opacity 0.3s ease 0.1s;

    br {
      display: none;
    }
  }

  & .custom-select__button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    background-color: rgba(255, 255, 255, 0);
    border: 1px solid ${({ theme }) => theme.color.grayLight};
    outline: none;
    cursor: pointer;
    padding: 19px;

    transition: border-color 0.3s ease;

    @media (hover: hover) {
      &:hover {
        border-color: ${({ theme }) => theme.color.black};
        border: 1px solid;
      }
    }

    &:focus {
      outline: 2px solid ${({ theme }) => theme.color.nepal};
      border: 1px solid rgba(255, 255, 255, 0);
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.2;
    }
  }

  & .custom-select__list {
    position: absolute;
    bottom: -1px;
    z-index: 1;

    width: 100%;
    max-height: 180px;
    margin: 0;
    padding: 0;

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-width: 8px;
    scrollbar-color: ${({ theme }) => theme.color.chambray} ${({ theme }) => theme.color.lightSeaGreen};

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.color.nepal};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.chambray};
    }

    list-style: none;

    background-color: #ffffff;
    outline: 2px solid ${({ theme }) => theme.color.nepal};
    transform: translateY(100%);
    visibility: hidden;
    opacity: 0;

    transition: visibility 0.3s ease, opacity 0.3s ease;
  }

  & .custom-select__item {
    padding: 14px 19px;

    outline: none;
    cursor: pointer;

    transition: background-color 0.3s ease;

    &[aria-selected="true"] {
      background-color: rgba(0, 0, 0, 0.1);

      @media (hover: hover) {
        &:hover:not(.focus-visible) {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      &.focus-visible:focus {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    &:focus {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  & .custom-select__error {
    position: absolute;
    bottom: -15px;
    left: 0;

    color: #ff1553;

    opacity: 0;

    transition: opacity 0.1s ease;
  }

  & .custom-select__icon {
    flex-shrink: 0;
    width: 20px;
    height: 10px;
    margin-left: auto;

    background-color: ${({ theme }) => theme.color.lightSeaGreen};

    transition: transform 0.3s ease;

    clip-path: polygon(100% 0, 100% 20%, 50% 100%, 0 20%, 0 0, 50% 80%);
  }

  &.is-disabled {
    pointer-events: none;
    cursor: text;

    .custom-select__list {
      display: none;
    }
  }

  &.open-up {
    .custom-select__list {
      bottom: auto;
      top: 0;
      transform: translateY(calc(-100% + 10px));
    }
  }

  &.is-open {
    .custom-select__list {
      visibility: visible;
      opacity: 1;
    }

    .custom-select__icon {
      transform: rotate(180deg);
    }

    .custom-select__label {
      opacity: 0;
    }
  }

  &.not-empty {
    .custom-select__text {
      opacity: 1;
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__placeholder {
      opacity: 0;
    }
  }

  &.is-invalid {
    .custom-select__error {
      opacity: 1;
    }

    .custom-select__button {
      outline: 2px solid #ff1553;

      &:focus,
      &:hover {
        border: 2px solid rgba(#ff1553, 0.5);
      }
    }
  }

  /* &.is-valid {
    .custom-select__button {
      border: 2px solid $color-eucalyptus;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-eucalyptus, 0.5);
      }
    }
  } */

  &--placeholder {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    &.is-open .custom-select__label {
      opacity: 1;
    }

    &.not-empty .custom-select__label {
      opacity: 1;
    }
  }

  &.slide-up.not-empty,
  &.slide-up.is-open {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    .custom-select__text {
      opacity: 1;
    }
  }

  .input-message {
    position: absolute;
    right: 0;
    bottom: -5px;

    &.is-invalid {
      color: #ff0000;
    }

    &.is-valid {
      color: #15ab07;
    }
  }
`;


export {
  StyledSelectDiv
}
